import Vue from "vue";
import {
  mdiAccountCash,
  mdiHome,
  mdiBankTransfer,
  mdiCashMultiple,
  mdiCashRegister,
  mdiCashUsd,
  mdiCashPlus,
  mdiQrcode,
  mdiCurrencyUsdOff,
  mdiShieldAccount,
  mdiCurrencyUsdCircleOutline,
} from "@mdi/js";

interface MenuItem {
  id: number;
  icon: string;
  title: string;
  sub?: string | undefined;
  route?: string | undefined;
  expand: boolean;
  items?: MenuItem[] | undefined;
}

export default Vue.extend({
  name: "AppMenuComponent",
  data() {
    return {
      links: [
        {
          id: 1,
          icon: mdiHome,
          title: "Inicio",
          route: "/",
          expand: false,
        },
        {
          id: 2,
          icon: mdiAccountCash,
          title: "Resumen Pagos",
          route: "/ResumenPagos",
          expand: false,
        },
        {
          id: 3,
          icon: mdiCashRegister,
          title: "Reportes Cajeros",
          expand: true,
          items: [
            {
              id: 1,
              icon: mdiCashRegister,
              title: "Reporte Cajeros",
              route: "/ReporteCajeros",
              expand: false,
            },
            {
              id: 2,
              icon: mdiCashRegister,
              title: "Reporte PLC - Preview",
              route: "/ReportePlc",
              expand: false,
            },
          ],
        },
        {
          id: 4,
          icon: mdiBankTransfer,
          title: "Reportes Cortes",
          expand: true,
          items: [
            {
              id: 1,
              icon: mdiBankTransfer,
              title: "Reporte Cortes",
              route: "/ReporteCortes",
              expand: false,
            },
            {
              id: 2,
              icon: mdiBankTransfer,
              title: "Detalle Cortes - Preview",
              route: "/DetalleCortes",
              expand: false,
            },
            {
              id: 3,
              icon: mdiBankTransfer,
              title: "Cortes TC",
              route: "/CorteTC",
              expand: false,
            },
          ],
        },
        {
          id: 5,
          icon: mdiCashUsd,
          title: "Reporte Toneleros",
          route: "/ReporteToneleros",
          expand: false,
        },
        {
          id: 6,
          icon: mdiCashMultiple,
          title: "Reportes Pagos",
          expand: true,
          items: [
            {
              id: 1,
              icon: mdiCashMultiple,
              title: "Pagos",
              route: "/ReportePagos",
              expand: false,
            },
            {
              id: 2,
              icon: mdiCashPlus,
              title: "Pagos Personalizados",
              route: "/ReportePagosPersonalizados",
              expand: false,
            },
            {
              id: 3,
              icon: mdiCashMultiple,
              title: "Pagos Gob. Chihuahua",
              route: "/ReporteGobChihuahua",
              expand: false,
            },
            {
              id: 4,
              icon: mdiQrcode,
              title: "Pagos QR",
              route: "/reporteQr",
              expand: false,
            },
          ],
        },
        {
          id: 7,
          icon: mdiCurrencyUsdOff,
          title: "Reporte CND",
          route: "/CambiosNoDispensados",
          expand: false,
        },
        {
          id: 8,
          icon: mdiCurrencyUsdOff,
          title: "Reporte CND - Preview",
          route: "/CND",
          expand: false,
        },
        {
          id: 9,
          icon: mdiShieldAccount,
          title: "Reporte Visitas",
          route: "/ReporteVisitas",
          expand: false,
        },
      ] as MenuItem[],

      linksExternos: [
        {
          id: 1,
          icon: mdiCashMultiple,
          title: "Pagos",
          route: "/ReportePagos",
          expand: false,
        },
        {
          id: 2,
          icon: mdiCurrencyUsdOff,
          title: "Reporte CND",
          route: "/CambiosNoDispensados",
          expand: false,
        },
        {
          id: 3,
          icon: mdiCashUsd,
          title: "Reporte Toneleros",
          route: "/ReporteToneleros",
          expand: false,
        },
        {
          id: 4,
          icon: mdiCurrencyUsdCircleOutline,
          title: "Pagar no dispensados",
          route: "/pagarCnd",
          expand: false,
        },
      ] as MenuItem[],
    };
  },

  computed: {
    Links(): MenuItem[] {
      const user = this.$store.getters["oidcStore/oidcUser"];

      if (user["user_type"] == "Empleado") {
        return this.links;
      } else {
        if (user["RSP.Empresa"] == "31f9d34b-5006-4ead-bc75-f57aebb60d4c") {
          //Vistas Yucatan
          return this.linksExternos;
        } else if (user["RSP.Empresa"] == "ba140eb3-990a-11e8-9a86-5600006e8c82") {
          /// TODO: Implementar Authorizacion configurable
          //Gobierno de chihuahua
          return [
            {
              id: 1,
              icon: mdiCashMultiple,
              title: "Pagos",
              route: "/ReportePagos",
              expand: false,
            },
            {
              id: 2,
              icon: mdiCashMultiple,
              title: "Pagos Gob. Chihuahua",
              route: "/ReporteGobChihuahua",
              expand: false,
            },
          ];
        } else {
          //Clientes que no son yucatan, arreglar despues
          return [
            {
              id: 1,
              icon: mdiCashMultiple,
              title: "Pagos",
              route: "/ReportePagos",
              expand: false,
            },
          ];
        }
      }
    },
    Nombre(): string {
      return (
        this.$store.getters["oidcStore/oidcUser"].family_name +
        " " +
        this.$store.getters["oidcStore/oidcUser"].given_name
      );
    },
    Iniciales(): string {
      const arr = this.Nombre.split(" ", 2);
      return (arr[0].charAt(0) + arr[1].charAt(0)).toUpperCase();
    },
  },

  mounted() {
    this.Links;
  },
});
